/** Ripples */
/** Elements */
.theme--light.v-sheet {
  background-color: #FFFFFF;
  border-color: #FFFFFF;
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.v-sheet {
  background-color: #424242;
  border-color: #424242;
  color: #FFFFFF;
}

.v-sheet {
  border-radius: 4px;
}
.v-sheet--tile {
  border-radius: 0;
}