/** Ripples */
/** Elements */
.v-carousel {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.v-carousel__controls {
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  display: flex;
  height: 50px;
  justify-content: center;
  list-style-type: none;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.v-carousel__controls > .v-item-group {
  flex: 0 1 auto;
}
.v-carousel__controls__item {
  margin: 0 8px;
}
.v-carousel__controls__item .v-icon {
  opacity: 0.5;
}
.v-carousel__controls__item--active .v-icon {
  opacity: 1;
  vertical-align: middle;
}
.v-carousel__controls__item:hover {
  background: none;
}
.v-carousel__controls__item:hover .v-icon {
  opacity: 0.8;
}

.v-carousel__progress {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.v-carousel .v-window-item {
  display: block;
  height: inherit;
  text-decoration: none;
}

.v-carousel--hide-delimiter-background .v-carousel__controls {
  background: transparent;
}

.v-carousel--vertical-delimiters .v-carousel__controls {
  height: 100% !important;
  width: 50px;
}