/** Ripples */
/** Elements */
.theme--light.v-overflow-btn .v-input__control::before,
.theme--light.v-overflow-btn .v-input__slot::before {
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.theme--light.v-overflow-btn.v-text-field--outline .v-input__control::before,
.theme--light.v-overflow-btn.v-text-field--outline .v-input__slot::before {
  background-color: transparent !important;
}
.theme--light.v-overflow-btn--segmented .v-input__append-inner, .theme--light.v-overflow-btn--editable:hover .v-input__append-inner, .theme--light.v-overflow-btn--editable.v-input--is-focused .v-input__append-inner, .theme--light.v-overflow-btn--editable.v-select--is-menu-active .v-input__append-inner {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.theme--light.v-overflow-btn:hover .v-input__slot, .theme--light.v-overflow-btn.v-input--is-focused .v-input__slot, .theme--light.v-overflow-btn.v-select--is-menu-active .v-input__slot {
  background: #FFFFFF;
}

.theme--dark.v-overflow-btn .v-input__control::before,
.theme--dark.v-overflow-btn .v-input__slot::before {
  background-color: rgba(255, 255, 255, 0.12) !important;
}
.theme--dark.v-overflow-btn.v-text-field--outline .v-input__control::before,
.theme--dark.v-overflow-btn.v-text-field--outline .v-input__slot::before {
  background-color: transparent !important;
}
.theme--dark.v-overflow-btn--segmented .v-input__append-inner, .theme--dark.v-overflow-btn--editable:hover .v-input__append-inner, .theme--dark.v-overflow-btn--editable.v-input--is-focused .v-input__append-inner, .theme--dark.v-overflow-btn--editable.v-select--is-menu-active .v-input__append-inner {
  border-left: 1px solid rgba(255, 255, 255, 0.12);
}
.theme--dark.v-overflow-btn:hover .v-input__slot, .theme--dark.v-overflow-btn.v-input--is-focused .v-input__slot, .theme--dark.v-overflow-btn.v-select--is-menu-active .v-input__slot {
  background: #424242;
}

.v-overflow-btn {
  margin-top: 12px;
  padding-top: 0;
}
.v-overflow-btn:not(.v-overflow-btn--editable) > .v-input__control > .v-input__slot {
  cursor: pointer;
}
.v-overflow-btn .v-select__slot {
  height: 48px;
}
.v-overflow-btn.v-input--dense .v-select__slot {
  height: 38px;
}
.v-overflow-btn.v-input--dense input {
  margin-left: 16px;
  cursor: pointer;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 16px;
}
.v-overflow-btn .v-input__slot {
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-overflow-btn .v-input__slot::after {
  content: none;
}
.v-overflow-btn .v-label {
  margin-left: 16px;
  top: calc(50% - 10px);
}
.v-overflow-btn .v-input__append-inner {
  width: 48px;
  height: 48px;
  align-self: auto;
  align-items: center;
  margin-top: 0;
  padding: 0;
  flex-shrink: 0;
}
.v-overflow-btn .v-input__append-outer,
.v-overflow-btn .v-input__prepend-outer {
  margin-top: 12px;
  margin-bottom: 12px;
}
.v-overflow-btn .v-input__control::before {
  height: 1px;
  top: -1px;
  content: "";
  left: 0;
  position: absolute;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.v-overflow-btn.v-input--is-focused .v-input__slot, .v-overflow-btn.v-select--is-menu-active .v-input__slot {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.v-overflow-btn .v-select__selections {
  width: 0px;
}
.v-overflow-btn--segmented .v-select__selections {
  flex-wrap: nowrap;
}
.v-overflow-btn--segmented .v-select__selections .v-btn {
  border-radius: 0;
  margin: 0;
  margin-right: -16px;
  height: 48px;
  width: 100%;
}
.v-overflow-btn--segmented .v-select__selections .v-btn__content {
  justify-content: start;
}
.v-overflow-btn--segmented .v-select__selections .v-btn__content::before {
  background-color: transparent;
}
.v-overflow-btn--editable .v-select__slot input {
  cursor: text;
}
.v-overflow-btn--editable .v-input__append-inner,
.v-overflow-btn--editable .v-input__append-inner * {
  cursor: pointer;
}