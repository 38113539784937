/** Ripples */
/** Elements */
/* Theme */
.theme--light.v-messages {
  color: rgba(0, 0, 0, 0.6);
}

.theme--dark.v-messages {
  color: rgba(255, 255, 255, 0.7);
}

.v-messages {
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
}
.v-application--is-ltr .v-messages {
  text-align: left;
}
.v-application--is-rtl .v-messages {
  text-align: right;
}
.v-messages__message {
  line-height: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}