/** Ripples */
/** Elements */
.v-image {
  z-index: 0;
}

.v-image__image,
.v-image__placeholder {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.v-image__image {
  background-repeat: no-repeat;
}
.v-image__image--preload {
  -webkit-filter: blur(2px);
          filter: blur(2px);
}
.v-image__image--contain {
  background-size: contain;
}
.v-image__image--cover {
  background-size: cover;
}