/** Ripples */
/** Elements */
.theme--light.v-divider {
  border-color: rgba(0, 0, 0, 0.12);
}

.theme--dark.v-divider {
  border-color: rgba(255, 255, 255, 0.12);
}

.v-divider {
  display: block;
  flex: 1 1 0px;
  max-width: 100%;
  height: 0px;
  max-height: 0px;
  border: solid;
  border-width: thin 0 0 0;
  transition: inherit;
}
.v-divider--inset:not(.v-divider--vertical) {
  max-width: calc(100% - 72px);
}
.v-application--is-ltr .v-divider--inset:not(.v-divider--vertical) {
  margin-left: 72px;
}
.v-application--is-rtl .v-divider--inset:not(.v-divider--vertical) {
  margin-right: 72px;
}
.v-divider--vertical {
  align-self: stretch;
  border: solid;
  border-width: 0 thin 0 0;
  display: inline-flex;
  height: inherit;
  min-height: 100%;
  max-height: 100%;
  max-width: 0px;
  width: 0px;
  vertical-align: text-bottom;
}
.v-divider--vertical.v-divider--inset {
  margin-top: 8px;
  min-height: 0;
  max-height: calc(100% - 16px);
}