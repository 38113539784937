/** Ripples */
/** Elements */
.theme--light.v-treeview {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-treeview--hoverable .v-treeview-node__root:hover::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root:hover::before {
  opacity: 0.04;
}
.theme--light.v-treeview--hoverable .v-treeview-node__root:focus::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root:focus::before {
  opacity: 0.12;
}
.theme--light.v-treeview--hoverable .v-treeview-node__root--active:hover::before, .theme--light.v-treeview--hoverable .v-treeview-node__root--active::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:hover::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active::before {
  opacity: 0.12;
}
.theme--light.v-treeview--hoverable .v-treeview-node__root--active:focus::before,
.theme--light.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:focus::before {
  opacity: 0.16;
}
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:hover::before, .theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active::before {
  opacity: 0.12;
}
.theme--light.v-treeview .v-treeview-node__root.v-treeview-node--active:focus::before {
  opacity: 0.16;
}
.theme--light.v-treeview .v-treeview-node--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-treeview .v-treeview-node--disabled .v-treeview-node__toggle,
.theme--light.v-treeview .v-treeview-node--disabled .v-treeview-node__checkbox {
  color: rgba(0, 0, 0, 0.38) !important;
}

.theme--dark.v-treeview {
  color: #FFFFFF;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root:hover::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root:hover::before {
  opacity: 0.08;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root:focus::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root:focus::before {
  opacity: 0.24;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root--active:hover::before, .theme--dark.v-treeview--hoverable .v-treeview-node__root--active::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:hover::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active::before {
  opacity: 0.24;
}
.theme--dark.v-treeview--hoverable .v-treeview-node__root--active:focus::before,
.theme--dark.v-treeview .v-treeview-node--click > .v-treeview-node__root--active:focus::before {
  opacity: 0.32;
}
.theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active:hover::before, .theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active::before {
  opacity: 0.24;
}
.theme--dark.v-treeview .v-treeview-node__root.v-treeview-node--active:focus::before {
  opacity: 0.32;
}
.theme--dark.v-treeview .v-treeview-node--disabled {
  color: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-treeview .v-treeview-node--disabled .v-treeview-node__toggle,
.theme--dark.v-treeview .v-treeview-node--disabled .v-treeview-node__checkbox {
  color: rgba(255, 255, 255, 0.5) !important;
}

.v-application--is-ltr .v-treeview > .v-treeview-node {
  margin-left: 0;
}
.v-application--is-rtl .v-treeview > .v-treeview-node {
  margin-right: 0;
}
.v-application--is-ltr .v-treeview > .v-treeview-node--leaf {
  margin-left: 16px;
}
.v-application--is-rtl .v-treeview > .v-treeview-node--leaf {
  margin-right: 16px;
}
.v-application--is-ltr .v-treeview > .v-treeview-node--leaf > .v-treeview-node__root {
  padding-left: 8px;
  padding-right: 8px;
}
.v-application--is-rtl .v-treeview > .v-treeview-node--leaf > .v-treeview-node__root {
  padding-right: 8px;
  padding-left: 8px;
}
.v-application--is-ltr .v-treeview-node {
  margin-left: 26px;
}
.v-application--is-rtl .v-treeview-node {
  margin-right: 26px;
}
.v-treeview-node--disabled {
  pointer-events: none;
}
.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root,
.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root:before {
  border-bottom-right-radius: 24px !important;
  border-top-right-radius: 24px !important;
}
.v-treeview-node.v-treeview-node--shaped .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root,
.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root:before {
  border-radius: 24px !important;
}
.v-treeview-node.v-treeview-node--rounded .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-treeview-node--excluded {
  display: none;
}
.v-treeview-node--click > .v-treeview-node__root,
.v-treeview-node--click > .v-treeview-node__root > .v-treeview-node__content > * {
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-application--is-ltr .v-treeview-node--leaf {
  margin-left: 26px;
}
.v-application--is-rtl .v-treeview-node--leaf {
  margin-right: 26px;
}
.v-application--is-ltr .v-treeview-node--leaf > .v-treeview-node__root {
  padding-left: 24px;
  padding-right: 8px;
}
.v-application--is-rtl .v-treeview-node--leaf > .v-treeview-node__root {
  padding-right: 24px;
  padding-left: 8px;
}
.v-treeview-node__root {
  display: flex;
  align-items: center;
  min-height: 48px;
  position: relative;
}
.v-application--is-ltr .v-treeview-node__root {
  padding-right: 8px;
}
.v-application--is-rtl .v-treeview-node__root {
  padding-left: 8px;
}
.v-treeview-node__root::before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-treeview-node__root.v-treeview-node--active .v-treeview-node__content .v-icon {
  color: inherit;
}
.v-treeview-node__content {
  align-items: center;
  display: flex;
  flex-basis: 0%;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
}
.v-treeview-node__content .v-btn {
  flex-grow: 0 !important;
  flex-shrink: 1 !important;
}
.v-treeview-node__label {
  flex: 1;
  font-size: inherit;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.v-treeview-node__label .v-icon {
  padding-right: 8px;
}
.v-treeview-node__checkbox {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-treeview-node__toggle {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.v-application--is-ltr .v-treeview-node__toggle {
  transform: rotate(-90deg);
}
.v-application--is-rtl .v-treeview-node__toggle {
  transform: rotate(90deg);
}
.v-application--is-ltr .v-treeview-node__toggle--open {
  transform: none;
}
.v-application--is-rtl .v-treeview-node__toggle--open {
  transform: none;
}
.v-treeview-node__toggle--loading {
  -webkit-animation: progress-circular-rotate 1s linear infinite;
          animation: progress-circular-rotate 1s linear infinite;
  transform: none;
}
.v-treeview-node__children {
  transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
}
.v-treeview--dense .v-treeview-node__root {
  min-height: 40px;
}
.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root,
.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root:before {
  border-bottom-right-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.v-treeview--dense.v-treeview-node--shaped .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}
.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root,
.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root:before {
  border-radius: 20px !important;
}
.v-treeview--dense.v-treeview-node--rounded .v-treeview-node__root {
  margin-top: 8px;
  margin-bottom: 8px;
}