/** Ripples */
/** Elements */
.v-date-picker-title {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  line-height: 1;
}
.v-application--is-ltr .v-date-picker-title .v-picker__title__btn {
  text-align: left;
}
.v-application--is-rtl .v-date-picker-title .v-picker__title__btn {
  text-align: right;
}
.v-date-picker-title__year {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.v-date-picker-title__date {
  font-size: 34px;
  text-align: left;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  padding-bottom: 8px;
  margin-bottom: -8px;
}
.v-date-picker-title__date > div {
  position: relative;
}
.v-date-picker-title--disabled {
  pointer-events: none;
}