/** Ripples */
/** Elements */
.theme--light.v-calendar-weekly {
  background-color: #FFFFFF;
  border-top: #e0e0e0 1px solid;
  border-left: #e0e0e0 1px solid;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday {
  border-right: #e0e0e0 1px solid;
  color: #000000;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: #f7f7f7;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__day {
  border-right: #e0e0e0 1px solid;
  border-bottom: #e0e0e0 1px solid;
  color: #000000;
}
.theme--light.v-calendar-weekly .v-calendar-weekly__day.v-outside {
  background-color: #f7f7f7;
}

.theme--dark.v-calendar-weekly {
  background-color: #303030;
  border-top: #9e9e9e 1px solid;
  border-left: #9e9e9e 1px solid;
}
.theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday {
  border-right: #9e9e9e 1px solid;
  color: #FFFFFF;
}
.theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday.v-past {
  color: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-calendar-weekly .v-calendar-weekly__head-weekday.v-outside {
  background-color: #202020;
}
.theme--dark.v-calendar-weekly .v-calendar-weekly__day {
  border-right: #9e9e9e 1px solid;
  border-bottom: #9e9e9e 1px solid;
  color: #FFFFFF;
}
.theme--dark.v-calendar-weekly .v-calendar-weekly__day.v-outside {
  background-color: #202020;
}

.v-calendar-weekly {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.v-calendar-weekly__head {
  display: flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.v-calendar-weekly__head-weekday {
  flex: 1 0 20px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding: 0px 4px 0px 4px;
  font-size: 11px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  white-space: nowrap;
}

.v-calendar-weekly__week {
  display: flex;
  flex: 1;
  height: unset;
  min-height: 0;
}

.v-calendar-weekly__day {
  flex: 1;
  width: 0;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  padding: 0px 0px 0px 0px;
  min-width: 0;
}
.v-calendar-weekly__day.v-present .v-calendar-weekly__day-month {
  color: currentColor;
}

.v-calendar-weekly__day-label {
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  box-shadow: none;
  text-align: center;
  margin: 4px 0 0 0;
}
.v-calendar-weekly__day-label .v-btn {
  font-size: 12px;
  text-transform: none;
}

.v-calendar-weekly__day-month {
  position: absolute;
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-shadow: none;
  top: 0;
  left: 36px;
  height: 32px;
  line-height: 32px;
}