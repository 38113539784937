/** Ripples */
/** Elements */
.theme--light.v-file-input .v-file-input__text {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-file-input .v-file-input__text--placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.theme--dark.v-file-input .v-file-input__text {
  color: #FFFFFF;
}
.theme--dark.v-file-input .v-file-input__text--placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.v-file-input input[type=file] {
  opacity: 0;
  max-width: 0;
  width: 0;
}

.v-file-input .v-file-input__text {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.v-file-input .v-file-input__text.v-file-input__text--chips {
  flex-wrap: wrap;
}
.v-file-input .v-file-input__text .v-chip {
  margin: 4px;
}

.v-file-input.v-text-field--filled:not(.v-text-field--single-line) .v-file-input__text {
  padding-top: 22px;
}

.v-file-input.v-text-field--outlined .v-text-field__slot {
  padding: 6px 0;
}
.v-file-input.v-text-field--outlined.v-input--dense .v-text-field__slot {
  padding: 3px 0;
}