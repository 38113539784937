/** Ripples */
/** Elements */
.v-snack {
  align-items: center;
  color: #FFFFFF;
  display: flex;
  font-size: 14px;
  left: 8px;
  pointer-events: none;
  position: fixed;
  right: 8px;
  text-align: left;
  transition-duration: 0.15s;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  z-index: 1000;
}
.v-snack--absolute {
  position: absolute;
}
.v-snack--top {
  top: 8px;
}
.v-snack--bottom {
  bottom: 8px;
}
.v-snack__wrapper {
  align-items: center;
  background-color: #323232;
  border-radius: 4px;
  display: flex;
  margin: 0 auto;
  pointer-events: auto;
  transition: inherit;
  transition-property: opacity, transform;
  min-width: 100%;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.v-snack__content {
  align-items: center;
  display: flex;
  min-height: 48px;
  justify-content: space-between;
  overflow: hidden;
  padding: 8px 16px;
  width: 100%;
}
.v-snack__content .v-btn.v-btn {
  color: #FFFFFF;
  flex: 0 0 auto;
  height: auto;
  min-width: auto;
  padding: 8px;
  width: auto;
}
.v-application--is-ltr .v-snack__content .v-btn.v-btn {
  margin: 0 -8px 0 24px;
}
.v-application--is-rtl .v-snack__content .v-btn.v-btn {
  margin: 0 24px 0 -8px;
}
.v-snack__content .v-btn.v-btn__content {
  margin: -2px;
}
.v-snack__content .v-btn.v-btn::before {
  display: none;
}
.v-snack--multi-line .v-snack__content {
  height: auto;
  min-height: 68px;
}
.v-snack--vertical .v-snack__content {
  align-items: stretch;
  flex-direction: column;
  height: auto;
  padding: 16px 16px 8px;
}
.v-snack--vertical .v-snack__content .v-btn.v-btn {
  align-self: flex-end;
  justify-self: flex-end;
  margin-left: 0;
  margin-top: 18px;
}
.v-snack--vertical .v-snack__content .v-btn__content {
  flex: 0 0 auto;
  margin: 0;
}

@media only screen and (min-width: 600px) {
  .v-snack__wrapper {
    min-width: 344px;
    max-width: 672px;
  }
  .v-snack--left .v-snack__wrapper {
    margin-left: 0;
  }
  .v-snack--right .v-snack__wrapper {
    margin-right: 0;
  }
  .v-application--is-ltr .v-snack__content .v-btn:first-of-type {
    margin-left: 42px;
  }
  .v-application--is-rtl .v-snack__content .v-btn:first-of-type {
    margin-right: 42px;
  }
}
.v-snack-transition-enter .v-snack__wrapper {
  transform: scale(0.8);
}
.v-snack-transition-enter .v-snack__wrapper, .v-snack-transition-leave-to .v-snack__wrapper {
  opacity: 0;
}