/** Ripples */
/** Elements */
/** Ripples */
/** Elements */
.theme--light.v-calendar-events .v-event-timed {
  border: 1px solid !important;
}

.theme--dark.v-calendar-events .v-event-timed {
  border: 1px solid !important;
}

.v-calendar .v-event {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  margin-right: -1px;
}
.v-calendar .v-event.v-event-start {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.v-calendar .v-event.v-event-end {
  width: 95%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.v-calendar .v-event-more {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  margin-right: 10px;
}
.v-calendar .v-event-timed-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-right: 10px;
  pointer-events: none;
}
.v-calendar .v-event-timed {
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  pointer-events: all;
}
.v-calendar.v-calendar-events .v-calendar-weekly__day {
  overflow: visible;
}