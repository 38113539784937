/** Ripples */
/** Elements */
.theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: rgba(0, 0, 0, 0.38);
}
.theme--light.v-data-table .v-data-table-header th.sortable:hover {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-data-table .v-data-table-header th.sortable.active {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
  color: rgba(0, 0, 0, 0.87);
}
.theme--light.v-data-table .v-data-table-header__sort-badge {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.87);
}

.theme--dark.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
  color: rgba(255, 255, 255, 0.5);
}
.theme--dark.v-data-table .v-data-table-header th.sortable:hover {
  color: white;
}
.theme--dark.v-data-table .v-data-table-header th.sortable.active {
  color: white;
}
.theme--dark.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
  color: white;
}
.theme--dark.v-data-table .v-data-table-header__sort-badge {
  background-color: rgba(255, 255, 255, 0.12);
  color: #FFFFFF;
}

.v-data-table-header th.sortable {
  pointer-events: auto;
  cursor: pointer;
  outline: 0;
}
.v-data-table-header th.active .v-data-table-header__icon, .v-data-table-header th:hover .v-data-table-header__icon {
  transform: none;
  opacity: 1;
}
.v-data-table-header th.desc .v-data-table-header__icon {
  transform: rotate(-180deg);
}

.v-data-table-header__icon {
  display: inline-block;
  opacity: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-data-table-header__sort-badge {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  border-radius: 50%;
  min-width: 18px;
  min-height: 18px;
  height: 18px;
  width: 18px;
}

.v-data-table-header-mobile th {
  height: initial;
}

.v-data-table-header-mobile__wrapper {
  display: flex;
}
.v-data-table-header-mobile__wrapper .v-select {
  margin-bottom: 8px;
}
.v-data-table-header-mobile__wrapper .v-select .v-chip {
  height: 24px;
}
.v-data-table-header-mobile__wrapper .v-select .v-chip__close.desc .v-icon {
  transform: rotate(-180deg);
}

.v-data-table-header-mobile__select {
  min-width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}