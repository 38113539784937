/** Ripples */
/** Elements */
.theme--light.v-breadcrumbs .v-breadcrumbs__divider, .theme--light.v-breadcrumbs .v-breadcrumbs__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}

.theme--dark.v-breadcrumbs .v-breadcrumbs__divider, .theme--dark.v-breadcrumbs .v-breadcrumbs__item--disabled {
  color: rgba(255, 255, 255, 0.5);
}

.v-breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex: 0 1 auto;
  list-style-type: none;
  margin: 0;
  padding: 18px 12px;
}
.v-breadcrumbs li {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
}
.v-breadcrumbs li .v-icon {
  font-size: 16px;
}
.v-breadcrumbs li:nth-child(even) {
  padding: 0 12px;
}

.v-breadcrumbs__item {
  align-items: center;
  display: inline-flex;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-breadcrumbs__item--disabled {
  pointer-events: none;
}

.v-breadcrumbs--large li {
  font-size: 16px;
}
.v-breadcrumbs--large li .v-icon {
  font-size: 16px;
}