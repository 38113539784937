/** Ripples */
/** Elements */
.theme--light.v-time-picker-clock {
  background: #e0e0e0;
}
.theme--light.v-time-picker-clock .v-time-picker-clock__item--disabled {
  color: rgba(0, 0, 0, 0.26);
}
.theme--light.v-time-picker-clock .v-time-picker-clock__item--disabled.v-time-picker-clock__item--active {
  color: rgba(255, 255, 255, 0.3);
}
.theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__hand {
  background-color: #bdbdbd;
}
.theme--light.v-time-picker-clock--indeterminate:after {
  color: #bdbdbd;
}
.theme--light.v-time-picker-clock--indeterminate .v-time-picker-clock__item--active {
  background-color: #bdbdbd;
}

.theme--dark.v-time-picker-clock {
  background: #616161;
}
.theme--dark.v-time-picker-clock .v-time-picker-clock__item--disabled {
  color: rgba(255, 255, 255, 0.3);
}
.theme--dark.v-time-picker-clock .v-time-picker-clock__item--disabled.v-time-picker-clock__item--active {
  color: rgba(255, 255, 255, 0.3);
}
.theme--dark.v-time-picker-clock--indeterminate .v-time-picker-clock__hand {
  background-color: #757575;
}
.theme--dark.v-time-picker-clock--indeterminate:after {
  color: #757575;
}
.theme--dark.v-time-picker-clock--indeterminate .v-time-picker-clock__item--active {
  background-color: #757575;
}

.v-time-picker-clock {
  border-radius: 100%;
  position: relative;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
  padding-top: 100%;
  flex: 1 0 auto;
}
.v-time-picker-clock__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}
.v-time-picker-clock__ampm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 10px;
}
.v-time-picker-clock__hand {
  height: calc(50% - 4px);
  width: 2px;
  bottom: 50%;
  left: calc(50% - 1px);
  transform-origin: center bottom;
  position: absolute;
  will-change: transform;
  z-index: 1;
}
.v-time-picker-clock__hand:before {
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: inherit;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  content: "";
  position: absolute;
  top: -4px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.v-time-picker-clock__hand:after {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  top: 100%;
  left: 50%;
  border-radius: 100%;
  border-style: solid;
  border-color: inherit;
  background-color: inherit;
  transform: translate(-50%, -50%);
}
.v-time-picker-clock__hand--inner:after {
  height: 14px;
}

.v-picker--full-width .v-time-picker-clock__container {
  max-width: 290px;
}

.v-time-picker-clock__inner {
  position: absolute;
  bottom: 27px;
  left: 27px;
  right: 27px;
  top: 27px;
}

.v-time-picker-clock__item {
  align-items: center;
  border-radius: 100%;
  cursor: default;
  display: flex;
  font-size: 16px;
  justify-content: center;
  height: 40px;
  position: absolute;
  text-align: center;
  width: 40px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transform: translate(-50%, -50%);
}
.v-time-picker-clock__item > span {
  z-index: 1;
}
.v-time-picker-clock__item:before, .v-time-picker-clock__item:after {
  content: "";
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 14px;
  width: 14px;
  transform: translate(-50%, -50%);
}
.v-time-picker-clock__item:after, .v-time-picker-clock__item:before {
  height: 40px;
  width: 40px;
}
.v-time-picker-clock__item--active {
  color: #FFFFFF;
  cursor: default;
  z-index: 2;
}
.v-time-picker-clock__item--disabled {
  pointer-events: none;
}

.v-picker--landscape .v-time-picker-clock__container {
  flex-direction: row;
}
.v-picker--landscape .v-time-picker-clock__ampm {
  flex-direction: column;
}