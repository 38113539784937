/** Ripples */
/** Elements */
.theme--light.v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea {
  color: #FFFFFF;
}

.theme--dark.v-textarea.v-text-field--solo-inverted.v-text-field--solo.v-input--is-focused textarea {
  color: rgba(0, 0, 0, 0.87);
}

.v-textarea textarea {
  flex: 1 1 auto;
  line-height: 18px;
  max-width: 100%;
  min-height: 32px;
  outline: none;
  padding: 7px 0;
  width: 100%;
}
.v-textarea .v-text-field__prefix {
  padding-top: 4px;
  align-self: start;
}
.v-textarea.v-text-field--box .v-text-field__prefix,
.v-textarea.v-text-field--box textarea, .v-textarea.v-text-field--enclosed .v-text-field__prefix,
.v-textarea.v-text-field--enclosed textarea {
  margin-top: 24px;
}
.v-textarea.v-text-field--box.v-text-field--single-line .v-text-field__prefix,
.v-textarea.v-text-field--box.v-text-field--single-line textarea, .v-textarea.v-text-field--box.v-text-field--outlined .v-text-field__prefix,
.v-textarea.v-text-field--box.v-text-field--outlined textarea, .v-textarea.v-text-field--enclosed.v-text-field--single-line .v-text-field__prefix,
.v-textarea.v-text-field--enclosed.v-text-field--single-line textarea, .v-textarea.v-text-field--enclosed.v-text-field--outlined .v-text-field__prefix,
.v-textarea.v-text-field--enclosed.v-text-field--outlined textarea {
  margin-top: 12px;
}
.v-textarea.v-text-field--box.v-text-field--single-line .v-label, .v-textarea.v-text-field--box.v-text-field--outlined .v-label, .v-textarea.v-text-field--enclosed.v-text-field--single-line .v-label, .v-textarea.v-text-field--enclosed.v-text-field--outlined .v-label {
  top: 18px;
}
.v-textarea.v-text-field--solo {
  align-items: flex-start;
}
.v-textarea.v-text-field--solo .v-input__prepend-inner,
.v-textarea.v-text-field--solo .v-input__prepend-outer,
.v-textarea.v-text-field--solo .v-input__append-inner,
.v-textarea.v-text-field--solo .v-input__append-outer {
  align-self: flex-start;
  margin-top: 16px;
}
.v-application--is-ltr .v-textarea.v-text-field--solo .v-input__append-inner {
  padding-left: 12px;
}
.v-application--is-rtl .v-textarea.v-text-field--solo .v-input__append-inner {
  padding-right: 12px;
}
.v-textarea--auto-grow textarea {
  overflow: hidden;
}
.v-textarea--no-resize textarea {
  resize: none;
}
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-right: -12px;
}
.v-application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot {
  margin-left: -12px;
}
.v-application--is-ltr .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-right: 12px;
}
.v-application--is-rtl .v-textarea.v-text-field--enclosed .v-text-field__slot textarea {
  padding-left: 12px;
}