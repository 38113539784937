/** Ripples */
/** Elements */
.v-data-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
}
.v-data-footer .v-btn {
  color: inherit;
}

.v-application--is-ltr .v-data-footer__icons-before .v-btn:last-child {
  margin-right: 7px;
}
.v-application--is-rtl .v-data-footer__icons-before .v-btn:last-child {
  margin-left: 7px;
}

.v-application--is-ltr .v-data-footer__icons-after .v-btn:first-child {
  margin-left: 7px;
}
.v-application--is-rtl .v-data-footer__icons-after .v-btn:first-child {
  margin-right: 7px;
}

.v-data-footer__pagination {
  display: block;
  text-align: center;
}
.v-application--is-ltr .v-data-footer__pagination {
  margin: 0 32px 0 24px;
}
.v-application--is-rtl .v-data-footer__pagination {
  margin: 0 24px 0 32px;
}

.v-data-footer__select {
  display: flex;
  align-items: center;
  flex: 0 0 0;
  justify-content: flex-end;
  white-space: nowrap;
}
.v-application--is-ltr .v-data-footer__select {
  margin-right: 14px;
}
.v-application--is-rtl .v-data-footer__select {
  margin-left: 14px;
}
.v-data-footer__select .v-select {
  flex: 0 1 0;
  padding: 0;
  position: initial;
}
.v-application--is-ltr .v-data-footer__select .v-select {
  margin: 13px 0 13px 34px;
}
.v-application--is-rtl .v-data-footer__select .v-select {
  margin: 13px 34px 13px 0;
}
.v-data-footer__select .v-select__selections {
  flex-wrap: nowrap;
}
.v-data-footer__select .v-select__selections .v-select__selection--comma {
  font-size: 12px;
}