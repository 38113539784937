/** Ripples */
/** Elements */
.v-badge {
  display: inline-block;
  line-height: 1;
  position: relative;
}
.v-badge__badge {
  align-items: center;
  border-radius: 11px;
  color: #FFFFFF;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-size: 14px;
  height: 22px;
  justify-content: center;
  line-height: normal;
  min-width: 22px;
  padding: 0 4px;
  position: absolute;
  top: -11px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-application--is-ltr .v-badge__badge {
  right: -22px;
}
.v-application--is-rtl .v-badge__badge {
  left: -22px;
}
.v-badge__badge .v-icon {
  font-size: 14px;
}
.v-badge--overlap .v-badge__badge {
  top: -8px;
}
.v-application--is-ltr .v-badge--overlap .v-badge__badge {
  right: -8px;
}
.v-application--is-rtl .v-badge--overlap .v-badge__badge {
  left: -8px;
}
.v-application--is-ltr .v-badge--overlap.v-badge--left .v-badge__badge {
  left: -8px;
  right: initial;
}
.v-application--is-rtl .v-badge--overlap.v-badge--left .v-badge__badge {
  right: -8px;
  left: initial;
}
.v-badge--overlap.v-badge--bottom .v-badge__badge {
  bottom: -8px;
  top: initial;
}
.v-application--is-ltr .v-badge--left .v-badge__badge {
  left: -22px;
  right: initial;
}
.v-application--is-rtl .v-badge--left .v-badge__badge {
  right: -22px;
  left: initial;
}
.v-badge--bottom .v-badge__badge {
  bottom: -11px;
  top: initial;
}